import {
  toTemperature,
  toPercentage,
  tokW,
  tokWh,
  toAmps,
  toVolts,
  toWatts,
  toBoolean,
  toSentenceCase,
  toMiles,
} from "@/utils";

type FieldValue = string | number | boolean | undefined;

type FormattedField = string | undefined | null;

const FIELD_FORMATTERS = {
  ambientTemperature: (value: number) => toTemperature(value),
  backupReserve: (value: number) => toPercentage(value),
  charge: (value: number) => tokWh(value),
  chargePercentage: (value: number) => toPercentage(value),
  chargeRate: (value: number) => tokW(value),
  chargerCurrent: (value: number) => toAmps(value),
  chargerVoltage: (value: number) => toVolts(value),
  chargerWattage: (value: number) => toWatts(value),
  chargeCompletedAt: (value: string) => value,
  chargeLimit: (value: number) => tokWh(value),
  chargingState: (value: string) => value,
  coolTarget: (value: number) => toTemperature(value),
  createdAt: (value: string) => value,
  fanMode: (value: string) => toSentenceCase(value),
  gridEnergy: (value: number) => tokWh(value),
  gridPower: (value: number) => tokW(value),
  gridStatus: (value: string) => toSentenceCase(value),
  heatTarget: (value: number) => toTemperature(value),
  isPluggedIn: (value: boolean) => toBoolean(value),
  isStormModeActive: (value: boolean) => toBoolean(value),
  isStormModeEnabled: (value: boolean) => toBoolean(value),
  operatingMode: (value: string) => value,
  power: (value: number) => tokW(value),
  strategy: (value: string) => toSentenceCase(value),
  whConsumed: (value: number) => tokWh(value),
  range: (value: number | undefined) =>
    value !== undefined ? toMiles(value) : "N/A",
} as const;

export const formatField = (
  fieldName: keyof typeof FIELD_FORMATTERS,
  value: FieldValue,
): FormattedField => {
  const formatter = FIELD_FORMATTERS[fieldName];

  return value !== undefined ? formatter(value as never) : "—";
};
