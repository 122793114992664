import { notEmpty } from "@/lib/utils";
import { isArray } from "lodash";

export function processGraphQLOperationErrorsAndThrow(error: any): never {
  const errors = error?.graphQLErrors ?? error;

  if (isArray(errors) && notEmpty(errors)) {
    const [firstError] =
      errors?.map((error) => error.extensions?.originalError ?? error) ?? [];

    throw firstError ?? error;
  }

  throw error;
}

type OmitTypename<T> = {
  [P in keyof T as P extends "__typename" ? never : P]: T[P];
};

type GraphQLResponse<TData extends Record<string, unknown>> = {
  data: TData;
  errors?: any[];
};

export function processSingleOperationGraphQLResponse<
  TData extends Record<string, unknown>,
  TKey extends keyof OmitTypename<TData>,
>(key: TKey) {
  return function (
    response: GraphQLResponse<TData>,
  ): OmitTypename<TData>[TKey] {
    if (notEmpty(response?.errors)) {
      return processGraphQLOperationErrorsAndThrow(response?.errors);
    }

    return response.data[key];
  };
}
