import {
  differenceInHours,
  format,
  formatDistanceToNow,
  isValid,
  startOfDay,
  subDays,
} from "date-fns";

export enum DateRanges {
  Last30Days = "last30days",
  Last7Days = "last7days",
  Yesterday = "yesterday",
  Today = "today",
}

export function parseDateRange(dateRange: DateRanges) {
  const now = new Date();
  switch (dateRange) {
    case DateRanges.Today:
      return {
        after: startOfDay(now).toISOString(),
        before: now.toISOString(),
      };
    case DateRanges.Yesterday: {
      const yesterday = subDays(now, 1);
      return {
        after: startOfDay(yesterday).toISOString(),
        before: startOfDay(now).toISOString(),
      };
    }
    case DateRanges.Last7Days: {
      const sevenDaysAgo = subDays(now, 7);
      return {
        after: startOfDay(sevenDaysAgo).toISOString(),
        before: now.toISOString(),
      };
    }
    case DateRanges.Last30Days: {
      const thirtyDaysAgo = subDays(now, 30);
      return {
        after: startOfDay(thirtyDaysAgo).toISOString(),
        before: now.toISOString(),
      };
    }
    default:
      return {
        after: startOfDay(subDays(now, 30)).toISOString(),
        before: now.toISOString(),
      };
  }
}

export function getDefaultDateFormat(withTime?: boolean) {
  const helperDate = new Date("2000-01-31").toLocaleDateString();
  switch (helperDate) {
    case "1/31/2000":
    case "01/31/2000":
      return withTime ? "MM/dd/yyyy HH:mm:ss" : "MM/dd/yyyy";
    case "31/1/2000":
    case "31/01/2000":
      return withTime ? "dd/MM/yyyy HH:mm:ss" : "dd/MM/yyyy";
    case "2000-1-31":
    case "2000-01-31":
      return withTime ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd";
    case "2000年1月31日":
    case "2000年01月31日":
      return withTime ? "yyyy年MM月dd日 HH:mm:ss" : "yyyy年MM月dd日";
    case "2000년 1월 31일":
    case "2000년 01월 31일":
      return withTime ? "yyyy년 MM월 dd일 HH:mm:ss" : "yyyy년 MM월 dd일";
    default:
      return "MM/dd/yyyy";
  }
}

export function formatDateTime(
  value: string | Date,
  withTimeOrFormat?: boolean | string,
) {
  const dateValue = new Date(value);

  if (isValid(dateValue) === false) {
    return value as string;
  }

  if (!withTimeOrFormat) {
    return format(dateValue, getDefaultDateFormat()) as string;
  }
  if (withTimeOrFormat === true) {
    return format(dateValue, getDefaultDateFormat(true)) as string;
  }
  return format(dateValue, withTimeOrFormat) as string;
}

export function withTimeAgo(
  value: string | Date,
  format: string = getDefaultDateFormat(true),
) {
  const dateValue = new Date(value);

  if (isValid(dateValue) === false) {
    return value as string;
  }

  const isInLast24h = differenceInHours(new Date(), dateValue) < 24;

  if (isInLast24h) {
    return formatDistanceToNow(new Date(value)) + " " + "ago";
  }

  return formatDateTime(value, format);
}
