import { ucFirst } from "@9yco/utils.utils";

export function camelCaseToWords(string: string) {
  return string?.replace(/([A-Z])/g, " $1").replace(/^./, function (string) {
    return string.toUpperCase();
  });
}

/**
 * Transforms a snake_case string to a sentence case string
 * @param string
 * @returns
 * @example snakeCaseToWords("SNAKE_CASE") => "Snake Case"
 */
export function snakeCaseToWords(string: string) {
  const processedString = string
    .toLowerCase()
    .split("_")
    .map((word) => ucFirst(word))
    .join(" ");

  return ucFirst(processedString);
}

export function enumToSentenceCase(string: string) {
  if (string.includes("_")) {
    return snakeCaseToWords(string);
  }

  if (string.toUpperCase() === string) {
    return ucFirst(string.toLowerCase());
  }

  return camelCaseToWords(string);
}

export function truncateMiddle(value: string, length: number = 16): string {
  if (!value) return value;

  // Handle invalid length - return original string for invalid lengths
  if (length < 5) {
    return value;
  }

  // Handle case where specified length is longer than string
  if (length > value.length) {
    return value;
  }

  // For strings under default length with no specified length
  if (value.length <= 16 && length === 16) {
    const totalChars = value.length - 3; // Account for ...
    const leadingChars = Math.ceil(totalChars / 2);
    const trailingChars = Math.floor(totalChars / 2);
    return `${value.slice(0, leadingChars)}...${value.slice(-trailingChars)}`;
  }

  // For strings over specified length
  const totalChars = length - 3; // Account for ...
  const leadingChars = Math.ceil(totalChars / 2);
  const trailingChars = Math.floor(totalChars / 2);
  return `${value.slice(0, leadingChars)}...${value.slice(-trailingChars)}`;
}

export function toSentenceCase(text: string): string {
  if (!text) return text;
  return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
}

export function toTitleCase(text: string): string {
  if (!text) return text;
  const articles = new Set([
    "a",
    "an",
    "the",
    "and",
    "but",
    "or",
    "for",
    "nor",
    "on",
    "at",
    "of",
    "to",
    "with",
  ]);
  return text
    .toLocaleLowerCase()
    .split(/\s+/)
    .map((word, index) =>
      articles.has(word) && index !== 0
        ? word
        : word.charAt(0).toLocaleUpperCase() + word.slice(1),
    )
    .join(" ");
}

export function formatPhoneNumber(phone: string): string {
  if (!phone) return phone;
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export const toPercentage = (value: number | undefined) =>
  value === undefined ? "N/A" : `${Math.round(value)}%`;

export const tokWh = (value: number) =>
  value === undefined ? null : `${(value / 1000).toFixed(1)} kWh`;

export const tokW = (value: number) =>
  value === undefined ? null : `${(value / 1000).toFixed(1)} kW`;

export const toAmps = (value: number) =>
  value === undefined ? null : `${value.toFixed(1)} A`;

export const toVolts = (value: number) =>
  value === undefined ? null : `${Math.round(value)} V`;

export const toWatts = (value: number) =>
  value === undefined ? null : `${Math.round(value)} W`;

export const toMiles = (value: number) =>
  value === undefined ? null : `${Math.round(value)} mi`;

export const toBoolean = (value: boolean | undefined): string | null =>
  value === undefined ? null : value ? "Yes" : "No";

export const toTemperature = (value: number, unit: "F" | "C" = "F") => {
  if (value === undefined) {
    return null;
  }
  // Validate temperature ranges
  const minF = -459.67; // Absolute zero in Fahrenheit
  if (unit === "F" && value < minF) {
    return `< ${minF}°F`;
  }
  if (unit === "C") {
    const celsius = (value - 32) * (5 / 9);
    const minC = -273.15; // Absolute zero in Celsius
    return celsius < minC ? `< ${minC}°C` : `${Math.round(celsius)}°C`;
  }
  return `${Math.round(value)}°F`;
};
