export * from "./data";
export * from "./date";
export * from "./graphql";
export * from "./string";

export * from "./formatField";

export function classNames(...args: string[]) {
  return args.filter(Boolean).join(" ");
}

export const celsiusToFahrenheit = (celsius: number) => (celsius * 9) / 5 + 32;
export const fahrenheitToCelsius = (fahrenheit: number) =>
  ((fahrenheit - 32) * 5) / 9;
