"use client";

// import { INextState } from "@/hooks/useGetNextState";
import { isArray } from "lodash";

import {
  SESSION_STORAGE_KEYS,
  sessionStorageService,
} from "../services/sessionStorageService";
interface INextState {
  next: string;
  data?: {
    slug: string;
  };
}

interface IStateUrlOptions {
  manufacturerSlug?: string;
}

interface IMessage<T = unknown> {
  type: string;
  data?: T;
}

export interface IErrorResponse {
  code: string;
  message: string;
}

// Should be kept in sync with the same enum in apps/connect/api/src/constants/connectState.ts
// @todo: Move to a shared package/location
export const enum CONNECT_STATE {
  START = "start",
  INTRO = "intro",

  MANUFACTURER_SELECTION = "manufacturers",
  MANUFACTURER_OAUTH = "manufacturers/oauth",
  MANUFACTURER_LOGIN = "manufacturers/login",
  MANUFACTURER_LOGIN_MFA = "manufacturers/login/mfa",

  LOCATION = "location",

  OAUTH_CODE = "oauth:code",
  SUCCESS = "success",
  ERROR = "error",
}

function getUrlForState(
  linkToken: string,
  state: string,
  options: IStateUrlOptions = {},
): string | null {
  switch (state) {
    case CONNECT_STATE.START:
      return `/${linkToken}`;
    case CONNECT_STATE.INTRO:
      return `/${linkToken}/intro`;
    case CONNECT_STATE.MANUFACTURER_SELECTION:
      return `/${linkToken}/manufacturers`;
    case CONNECT_STATE.MANUFACTURER_OAUTH:
      return `/${linkToken}/manufacturers/${options.manufacturerSlug}`;
    case CONNECT_STATE.MANUFACTURER_LOGIN:
      return `/${linkToken}/manufacturers/${options.manufacturerSlug}/login`;
    case CONNECT_STATE.MANUFACTURER_LOGIN_MFA:
      return `/${linkToken}/manufacturers/${options.manufacturerSlug}/login/mfa`;
    case CONNECT_STATE.LOCATION:
      return `/${linkToken}/location`;
    case CONNECT_STATE.SUCCESS:
      return `/${linkToken}/success`;
    case CONNECT_STATE.ERROR:
      return `/${linkToken}/error`;
    default:
      return null;
  }
}

export function getUrlForNextState(
  linkToken: string,
  nextState: INextState,
): string | null {
  return getUrlForState(linkToken, nextState.next, {
    manufacturerSlug: nextState.data?.slug || "",
  });
}

export function sendHostMessage<T>(message: IMessage<T>): void {
  if (!window.opener || window.opener === window) {
    return;
  }

  const hostOrigin = sessionStorageService.get(SESSION_STORAGE_KEYS.hostOrigin);

  if (!hostOrigin) {
    console.warn("No host origin found in session storage");
    return;
  }

  window.opener.postMessage(message, hostOrigin);
}

export function onBeforeUnloadHandler(): void {
  sendHostMessage({
    type: "close",
  });
}

if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', onBeforeUnloadHandler);
}

export function classNames(...args: (string | boolean | undefined)[]) {
  return args.filter(Boolean).join(" ");
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function isNil(value: any): value is null | undefined {
  return value === null || value === undefined;
}

export function processGraphQLOperationErrorsAndThrow(error: any): never {
  const errors = error?.graphQLErrors ?? error;

  if (isArray(errors) && notEmpty(errors)) {
    const [firstError] =
      errors?.map((error) => error.extensions?.originalError ?? error) ?? [];

    throw firstError ?? error;
  }

  throw error;
}

type OmitTypename<T> = {
  [P in keyof T as P extends "__typename" ? never : P]: T[P];
};

type GraphQLResponse<TData extends Record<string, unknown>> = {
  data: TData;
  errors?: IErrorResponse[];
};

export function processSingleOperationGraphQLResponse<
  TData extends Record<string, unknown>,
  TKey extends keyof OmitTypename<TData>,
>(key: TKey) {
  return function (
    response: GraphQLResponse<TData>,
  ): OmitTypename<TData>[TKey] {
    if (notEmpty(response?.errors) && response.errors.length > 0) {
      return processGraphQLOperationErrorsAndThrow(response?.errors);
    }

    return response.data[key];
  };
}
